
/**************************/
/* FONT SIZE */
/**************************/

.font-6 { font-size: 0.375rem !important; }
.font-8 { font-size: 0.500rem !important; }
.font-9 { font-size: 0.5625rem !important; }
.font-10 { font-size: 0.625rem !important; }
.font-11 { font-size: 0.7rem !important; }
.font-12 { font-size: 0.750rem !important; }
.font-14 { font-size: 0.875rem !important; }
.font-16 { font-size: 1rem !important; }
.font-18 { font-size: 1.125rem !important; }
.font-20 { font-size: 1.250rem !important; }
.font-24 { font-size: 1.5rem !important; }

div.main-page { 
    
    // max-width: 1800px;
    margin: 0 auto;
    width: 90%;
    .main-container {
        width: 100%;
        margin: 0 auto;
        height: 100%;
        display: flex;
        flex-direction: column;
    }


    .medium-container {
        max-width: calc(100% - 248px);
    }

    .main-content {
        margin: 0 0 2rem 0;
        flex-grow: 1;
        overflow: auto;
        display: flex;
        justify-content: space-between;
    }

    .inner-content {
        overflow: auto;
        max-height: calc(100% - 0rem);
        min-height: calc(100% - 0rem);
        margin: 0;
        position: relative;
    }

    

    @media screen and (min-width: 1920px) {
        
    }

    // HEADER

    .main-header {
        padding: 1.4rem 0;
        width: 100%;
        h4.header-title {
            font-size: 1rem;
            font-weight: 600;
        }
        button {
            &:first-child {
                font-size: 1rem;  
            }
        }
    }

    @media screen and(max-width: 767px) {
        .main-content {
            justify-content: flex-start;
            // flex-direction: column;

        }
        .action-side {
            order: -1;
        }
    }


    @media screen and (max-width: 1919px)
    {

        .medium-container {
            max-width: calc(100% - 220px);
        }
        
    }


    @media screen and (max-width: 1279px)
    {
        .medium-container {
            max-width: 100%;
        }
    }
}

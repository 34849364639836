.searchbar-component {
    
    .search-input {
        border-radius: 1rem;
        border: none;
        padding: .55rem 1.75rem;
        outline: none;
        background: #F4F4F4;
        width: 100%;
        font-size: calc(1em - 5px);
    }
}
.date-picker-component {
    table {
        width: 100%;
    }
    th {
        font-weight: 400;
    }
    th,
    td {
        font-size: calc(1rem - 4px);
        text-align: center;
        padding: .5rem;
    }

    td {
        span {
            transition: .1s all ease;
            margin: 0 auto;
            display: block;
            border-radius: 50%;
            // background: red;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            user-select: none;
            cursor: pointer;
            font-weight: 600;
            border: 1px solid transparent;
            &:hover {
                background: #F28705;
                border-color: #F28705;
                opacity: .9;
                color: #FFF;
            }
            &.selected {
                background: #F28705 !important;
                border-color: #F28705;
                color: #FFF;
                opacity: 1 !important;
            }
            &.not-current {
                color: #AAAAAA;
            }
            &.today {
                border-color: #000;
            }
        }
    }
}
.table {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    // flex-wrap: wrap;
    .table-grid-header {
        width: 100% !important;
        display: flex;
        position: relative;
        align-items: flex-start;
        align-content: center;
        justify-content: flex-start;
        border-bottom: 2px solid #AAAAAA !important;
        height: fit-content;
        margin-bottom: .25em;

        &.grouping {
            border-bottom: 0 !important;
            margin-bottom: 16px;
        }

        .th {
            flex: 1 1 0;
            min-height: 15px;
            padding: 0.9em 1.1em;

            position: sticky;
            top: -1px;
            background: #FFF;
            color: #AAAAAA;
            font-size: 0.55rem !important;
            font-weight: 600;
            border: none !important;
            cursor: pointer;
            text-align: left;
            z-index: 1;

            i {
                margin-bottom: 3px;
            }
            &.narrow-column {
                flex: none;
                width: 60px;
            }
        }

        .th-active{
            color: #000 !important;
        }

        .th-check {
            //right: 0;
            display: flex;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            margin: 0 !important;
            //position: sticky;
        }
    }

    .table-grid {
        width: 100%;

        &.grouping {
            .tr {
                border-radius: 8px;
                border-bottom: 0 !important;
                background-color: rgba(242, 135, 5, 0.6) !important;

                &.selected {
                    border: 0 !important;
                    background: rgba(66, 172, 222, 0.4) !important;
                }

                .td {
                    font-weight: bold !important;
                }
            }
        }

        .tr {
            width: 100%;
            display: flex;
            cursor: pointer;
            align-items: flex-start;
            justify-content: flex-start;
            border-bottom: 1px solid #CCC;
            transition: all 0.3s ease-in-out;

            overflow-y: hidden;
            .td {
                flex: 1 1 0;
                line-break: anywhere;

                font-size: .55rem;
                padding: 0.9em 1.1em;

                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                &.narrow-column {
                    flex: none;
                    width: 60px;
                }

                
                &.td-check {
                    right: 0;
                    display: flex;
                    position: sticky;
                    align-self: flex-start;
                    justify-self: flex-start;
                }
            }

            &.selected {
                border: 0 !important;
                background: rgba(66, 172, 222, 0.4) !important;
            }
        }

        .bg-menu {
            background-color: rgba(#F28705, .3) !important;
          }

        .tr:hover {
            background-color: rgba(66, 172, 222, 0.1);
        }
        .tr.bg-menu:hover {
            background-color: rgba(#F28705, .3) !important;
        }
    }
}

@media (max-width: 1515px) {
    .table {
        overflow-x: hidden !important;

        .table-grid, .table-grid-header {

        }
    }
}

.custom-check { filter: hue-rotate(160deg) }

input[type=checkbox] {
    transform: scale(1);
    margin: 10px;
    cursor: pointer;
}


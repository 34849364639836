@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../../fonts/source-sans-pro/SourceSansPro-BlackItalic.woff2') format('woff2'),
  url('../../../fonts/source-sans-pro/SourceSansPro-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../../fonts/source-sans-pro/SourceSansPro-Black.woff2') format('woff2'),
  url('../../../fonts/source-sans-pro/SourceSansPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../../fonts/source-sans-pro/SourceSansPro-Bold.woff2') format('woff2'),
  url('../../../fonts/source-sans-pro/SourceSansPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../../fonts/source-sans-pro/SourceSansPro-BoldItalic.woff2') format('woff2'),
  url('../../../fonts/source-sans-pro/SourceSansPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../../fonts/source-sans-pro/SourceSansPro-Italic.woff2') format('woff2'),
  url('../../../fonts/source-sans-pro/SourceSansPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../../fonts/source-sans-pro/SourceSansPro-LightItalic.woff2') format('woff2'),
  url('../../../fonts/source-sans-pro/SourceSansPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../../fonts/source-sans-pro/SourceSansPro-ExtraLight.woff2') format('woff2'),
  url('../../../fonts/source-sans-pro/SourceSansPro-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../../fonts/source-sans-pro/SourceSansPro-ExtraLightItalic.woff2') format('woff2'),
  url('../../../fonts/source-sans-pro/SourceSansPro-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../../fonts/source-sans-pro/SourceSansPro-Light.woff2') format('woff2'),
  url('../../../fonts/source-sans-pro/SourceSansPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../../fonts/source-sans-pro/SourceSansPro-Regular.woff2') format('woff2'),
  url('../../../fonts/source-sans-pro/SourceSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../../fonts/source-sans-pro/SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
  url('../../../fonts/source-sans-pro/SourceSansPro-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../../fonts/source-sans-pro/SourceSansPro-SemiBold.woff2') format('woff2'),
  url('../../../fonts/source-sans-pro/SourceSansPro-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

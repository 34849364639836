@import 'assets/icons/themify-icons/themify-icons.css';
@import "assets/css/fonts/index";

body,
html,
#root {
  height: 100%;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */

}

*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-size: 16px;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  //font-weight: 600;
  background: #F5F8FA;
}


// @media screen and (max-width: 767px)
// {
//   body {
//     font-size: 14px;
//   }
// }

@media screen and (min-width: 1280px)
{
  html {
    font-size: 18px;
  }
}


@media screen and (min-width: 1920px)
{
  html {
    font-size: 20px;
  }
}



/* MARGINS / PADDINGS */

.m-0 { margin:0!important; }
.m-1 { margin:.25rem!important; }
.m-2 { margin:.5rem!important; }
.m-3 { margin:1rem!important; }
.m-4 { margin:1.5rem!important; }
.m-5 { margin:3rem!important; }

.mt-0 { margin-top:0!important; }
.mr-0 { margin-right:0!important; }
.mb-0 { margin-bottom:0!important; }
.ml-0 { margin-left:0!important; }
.mx-0 { margin-left:0!important;margin-right:0!important; }
.my-0 { margin-top:0!important;margin-bottom:0!important; }

.mt-1 { margin-top:.25rem!important; }
.mr-1 { margin-right:.25rem!important; }
.mb-1 { margin-bottom:.25rem!important; }
.ml-1 { margin-left:.25rem!important; }
.mx-1 { margin-left:.25rem!important;margin-right:.25rem!important; }
.my-1 { margin-top:.25rem!important;margin-bottom:.25rem!important; }

.mt-2 { margin-top:.5rem!important; }
.mr-2 { margin-right:.5rem!important; }
.mb-2 { margin-bottom:.5rem!important; }
.ml-2 { margin-left:.5rem!important; }
.mx-2 { margin-right:.5rem!important;margin-left:.5rem!important; }
.my-2 { margin-top:.5rem!important;margin-bottom:.5rem!important; }

.mt-3 { margin-top:1rem!important; }
.mr-3 { margin-right:1rem!important; }
.mb-3 { margin-bottom:1rem!important; }
.ml-3 { margin-left:1rem!important; }
.mx-3 { margin-right:1rem!important;margin-left:1rem!important; }
.my-3 { margin-bottom:1rem!important;margin-top:1rem!important; }

.mt-4 { margin-top:1.5rem!important; }
.mr-4 { margin-right:1.5rem!important; }
.mb-4 { margin-bottom:1.5rem!important; }
.ml-4 { margin-left:1.5rem!important; }
.mx-4 { margin-right:1.5rem!important;margin-left:1.5rem!important; }
.my-4 { margin-top:1.5rem!important;margin-bottom:1.5rem!important; }

.mt-5 { margin-top:3rem!important; }
.mr-5 { margin-right:3rem!important; }
.mb-5 { margin-bottom:3rem!important; }
.ml-5 { margin-left:3rem!important; }
.mx-5 { margin-right:3rem!important;margin-left:3rem!important; }
.my-5 { margin-top:3rem!important;margin-bottom:3rem!important; }

.mt-auto { margin-top:auto!important; }
.mr-auto { margin-right:auto!important; }
.mb-auto { margin-bottom:auto!important; }
.ml-auto { margin-left:auto!important; }
.mx-auto { margin-right:auto!important;margin-left:auto!important; }
.my-auto { margin-bottom:auto!important;margin-top:auto!important; }

.p-0 { padding:0!important; }
.p-1 { padding:.25rem!important; }
.p-2 { padding:.5rem!important; }
.p-3 { padding:1rem!important; }
.p-4 { padding:1.5rem!important; }
.p-5 { padding:3rem!important; }

.pt-0 { padding-top:0!important; }
.pr-0 { padding-right:0!important; }
.pb-0 { padding-bottom:0!important; }
.pl-0 { padding-left:0!important; }
.px-0 { padding-left:0!important;padding-right:0!important; }
.py-0 { padding-top:0!important;padding-bottom:0!important; }

.pt-1 { padding-top:.25rem!important; }
.pr-1 { padding-right:.25rem!important; }
.pb-1 { padding-bottom:.25rem!important; }
.pl-1 { padding-left:.25rem!important; }
.px-1 { padding-left:.25rem!important;padding-right:.25rem!important; }
.py-1 { padding-top:.25rem!important;padding-bottom:.25rem!important; }

.pt-2 { padding-top:.5rem!important; }
.pr-2 { padding-right:.5rem!important; }
.pb-2 { padding-bottom:.5rem!important; }
.pl-2 { padding-left:.5rem!important; }
.px-2 { padding-right:.5rem!important;padding-left:.5rem!important; }
.py-2 { padding-top:.5rem!important;padding-bottom:.5rem!important; }

.pt-3 { padding-top:1rem!important; }
.pr-3 { padding-right:1rem!important; }
.pb-3 { padding-bottom:1rem!important; }
.pl-3 { padding-left:1rem!important; }
.py-3 { padding-bottom:1rem!important;padding-top:1rem!important; }
.px-3 { padding-right:1rem!important;padding-left:1rem!important; }

.pt-4 { padding-top:1.5rem!important; }
.pr-4 { padding-right:1.5rem!important; }
.pb-4 { padding-bottom:1.5rem!important; }
.pl-4 { padding-left:1.5rem!important; }
.px-4 { padding-right:1.5rem!important;padding-left:1.5rem!important; }
.py-4 { padding-top:1.5rem!important;padding-bottom:1.5rem!important; }

.pt-5 { padding-top:3rem!important; }
.pr-5 { padding-right:3rem!important; }
.pb-5 { padding-bottom:3rem!important; }
.pl-5 { padding-left:3rem!important; }
.px-5 { padding-right:3rem!important;padding-left:3rem!important; }
.py-5 { padding-top:3rem!important;padding-bottom:3rem!important; }


/* DISPLAYS */
.d-flex { display: flex !important; }
.d-block { display: block !important; }
.d-i-block { display: inline-block !important; }
.d-none { display: none !important; }


/* BUTTONS */

button {
  &:disabled {
    opacity: .3 !important;
  }
}
.btn {
  padding: .75em 1em;
  border: none;
  outline: none;
  border-radius: .75rem;
  font-size: calc(1em - 6px);
  transition: .3s all ease;
  font-weight: bold;
  background: #F5F8FA;
  color: #000;
  border: 1px solid transparent;
  text-decoration: none;
  cursor: pointer;
  &:disabled {
    opacity: .3 !important;
  }
  &.btn-square {
    border-radius: 0 !important;
  }
  &.btn-clear {
    background: transparent;
    color: #000;
  }
  &.btn-large {
    padding: .75rem 1.8rem;
    border-radius: 1em;
  }
  &.btn-block {
    padding: .75rem 1.8rem;
    width: 100%;
    border-radius: 1em;
  }
  &:active {
    opacity: .7;
  }
  &.btn-orange {
    background: #F28705;
    color: #FFF;
  }
  &.btn-orange-outline {
    background: #FFF;
    border-color: #F28705;
    color: #F28705;
  }
  &.btn-orange-light {
    background: #FFEEDA;
    color: #F28705;
  }

  &.btn-blue {
    background: #42ACDE;
    color: #FFF;
  }
  &.btn-blue-outline {
    color: #42ACDE;
    background: #FFF;
    border-color: #42ACDE;
  }
  &.btn-light-blue {
    background: #E6F4FC;
    color: #42ACDE;
  }
  &.btn-danger {
    background: #C50532;
    color: #FFF;
  }
  &.btn-danger-outline {
    color: #C50532;;
    background: #FFF;
    border-color: #C50532;
  }
  &.btn-dark {
    background: #000;
    color: #FFF;
  }
}


// FORM

.small-form {
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
}

@media screen and (max-width: 767px)
{

}

.form-title {
  font-size: calc(1rem + 2px) !important;
  font-weight: 700;
  margin-bottom: 1rem;
}

label {
  display: block;
  color: #000;
  font-size: calc(1rem - 5px);
  font-weight: 500;
  margin-bottom: .12rem;
}

textarea.form-field {
  resize: none;
  height: 80px;
}
// .form-field:focus {
//   border-color: #000;
// }




// POSITION

.position-relative { position: relative !important; }
.position-absolute { position: absolute !important; }


.border-bottom {
  border-bottom: 1px solid #CCC;
}

.border-right-half {
  border-right: .5px solid #00000029 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

// FONT WEIGHT

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}


.fw-light {
  font-weight: 300 !important;
}
.fw-bold {
  font-weight: bold !important;
}


.border-bottom-gray {
  border-bottom: 1px solid #CCC;
}

.width-fit-content {
  width: fit-content;
}


.text-gray { color: #AAAAAA !important; }
.text-blue { color: #42ACDE !important; }
.text-red { color: #C50532 !important; }
.text-green { color: #0A602A !important; }
.text-orange { color: #F0A926 !important; }
.text-center { text-align: center !important; }
.text-left {text-align: left !important;}

.text-right { text-align: right !important; }



// ITEM CARD
.item-header {
  padding: 1rem;
  background-color: #C50532;
  color: #FFF;
  border-radius: 1rem;
  transition: .3s all ease;
  cursor: pointer;
  &.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.valid {
    background-color: #0A602A;
    &.blue {
      background: #42ACDE !important;
    }
  }
}

.item-content {
  max-height: 0;
  transition: .25s max-height linear;
  padding: 0 0 !important;
  border: 1px solid transparent;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  overflow: hidden;
  &.expanded {
    padding: 1rem !important;
    max-height: 500px;
    border-color: rgba(0, 0, 0, 0.205);
  }
}




.modal {
  position: fixed;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  div.modal-content {
    position: absolute;
    padding: 1rem;
    border-radius: 1rem;
    background: #FFF;
    top: 50%;
    left: 50%;
    right: -48%;
    bottom: -48%;
    max-width: 700px;
    max-height: 100%;
    overflow: auto;
    transform: translate(-50%, -50%);
  }
}
.sticky-header {
  position: sticky;
  z-index: 4;
  top: -1px;
  background: #FFF;
}




.no-select {
  user-select: none !important;
}

@media screen and (max-width: 767px) {
  .no-mobile {
    display: none;
  }
}

.card {
  // box-shadow: 0 3px 6px #00000029;
  background: #FFF;
  border-radius: calc(1rem - 8px);
  position: relative;
  width: 100%;
  //max-height: 100%;
  height: auto;
  overflow: auto;
  max-width: 100%;
  .card-content {
    margin: 20px 24px;
    max-width: 100%;
    overflow: auto;
    max-height: 100%;
    height: calc(100% - 40px);

  }
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

// .card-height{
//   padding: 1.2rem 1.3rem;
//   max-height: calc(100vh - 113px - 55px - 1rem);
//   overflow: scroll;
//   min-height: calc(100vh - 113px - 55px - 1rem);
// }


// .card-height-2 {
//   padding: 1.2rem 1.3rem;
//   max-height: calc(100vh - 113px - 55px - 3rem);
//   overflow: scroll;
//   min-height: calc(100vh - 113px - 55px - 3rem);
// }



// TEXTS

.tiny-text {
  font-size: calc(1rem - 10px);
  font-weight: 500;
}

.small-text {
  font-size: calc(1rem - 6px);
  font-weight: 500;
}

.regular-text {
  font-size: calc(1rem - 4px);
  font-weight: 600;
}

.subtitle-text {
  font-size: calc(1rem - 5px);
  font-weight: 600;
  color: #AAA;
  text-transform: uppercase;
}

.title-text {
  font-size: calc(1rem - 2px);
  font-weight: 600;
}


.fs-14{
  font-size: .7rem;
}

.rounded-card {
  border-radius: 8px !important;
}

.rounded {
  border-radius: 50%;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.bg-white {
  background: #FFF;
}

.bg-gray {
  background-color: #F4F4F4 !important;
}
.bg-red {
  background-color: red !important;
}


.avatar {
  background: #42ACDE;
  color: #FFF;
  display: inline-flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 10px;
  align-items: center;
  justify-content: center;
}


.btn-fake {
  cursor: pointer;
  user-select: none;
  &:active {
    opacity: .7;
  }
}


.row-outlined {
  border: 1px solid #CCC;
  padding: .75rem 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: .5rem;
  user-select: none;
  cursor: pointer;
  transition: .3s opacity ease;
  position: relative;
  &.grid {
    padding-bottom: 1.2rem;
    min-height: 6rem;
    border-radius: 0 !important;
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid #00000029;
    div.form-group {
      width: 90%;
      margin: 0 auto;
    }
    &:nth-child(odd) {
      border-right: 1px solid #00000029;
    }
  }
  &:active {
    opacity: .55;
  }
  &.no-action {
    &:active {
      opacity: 1 !important;
    }
  }
  &.light {
    background: #F4F4F4;
    border-color: #F4F4F4;
    color: #000;
  }
  &.orange {
    background: #FFEEDA;
    border-color: #F28705;
    color: #000;
  }
  &.selected {
    background: #F5F8FA;
    border-color: #F5F8FA;
  }
  &.selected-2 {
    background: #E6F4FC;
    border-color: #E6F4FC;
  }
  .actions {
    height: 24px;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.dot-color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: .25rem;
  margin-bottom: .15rem;
  border: 1px solid transparent;
  &.red {
    background: rgb(185, 9, 9);
  }
  &.green {
    background: rgb(2, 99, 2);
  }
  &.blue {
    background: rgb(7, 7, 151);
  }
  &.yellow {
    background: rgb(197, 197, 4);
  }
  &.black {
    background: #000;
  }
  &.white {
    border-color: #000;
  }
}

span.status {
  font-size: calc(1rem - 7px);
  padding: .333rem .833rem;
  color: #FFF;
  font-weight: 500;
  display: inline-block;
  background: #0A602A;
  border-radius: .3rem;
  text-transform: uppercase;
  // ORDER STATUSES
  &.assigned {
    background: #199A49;
  }
  &.picked-up {
    background: #EEEEEE;
    color: #888888;
  }
  &.ready {
    background: #D5EFD4;
    color: #199A49;
  }
  &.in-progress {
    background: #6BCA71;
  }

  &.late,
    // PAYMENT STATUTES
  &.past-due,
  &.not-paid {
    background: #C50532;
  }
  &.invoiced {
    background: #F9B6AC;
  }
  &.needs-new {
    background: #F68574;
  }
  &.paid {
    background: #FDF1E2;
    color: #FF8000;
  }
}

.fst-style {
  font-style: italic;
}

.rc-menu-container {
  // width: 100% !important;
  ul[role='menu'] {
    
    min-width: max-content !important;
  }
}


.rc-menu--open.submenu,
.rc-menu--closing.submenu {
  width: auto !important;
}

.btn-option {
  color: #AAAAAA;
  background: #FFF;
  cursor: pointer;
  outline: none;
  border: 1px solid #AAAAAA;
  font-size: calc(1rem - 8px) !important;
  margin: 0;
  padding: calc(1rem - 10px) calc(1rem - 4px);
  transition: .3s all ease;
  &:active {
    opacity: .7;
  }
  &:first-child {

    border-top-left-radius: calc(1rem - 4px);
    border-bottom-left-radius: calc(1rem - 4px);
  }
  &:last-child {

    border-top-right-radius: calc(1rem - 4px);
    border-bottom-right-radius: calc(1rem - 4px);
  }
  &.active {
    background: #42ACDE !important;
    color: #FFF;
    border-color: #42ACDE;
  }
}


.cursor-pointer {
  cursor: pointer !important;
}

//

.overflow-auto {
  overflow: auto;
}


/** badges **/

.badge-rounded {
  color: white;

  font-size: 0.7rem;
  font-weight: normal;

  padding: 8px 14px;
  border-radius: 20px;
  opacity: 1;

  transition: 0.2s ease-in;
}

.badge {
  color: #FFF;
  background: #000;

  font-size: 0.6rem;
  font-weight: normal;

  white-space: nowrap;
  letter-spacing: 1px;

  padding: 5px 12px;
  border-radius: 4px;
  opacity: 1;
  display: inline-block;
  user-select: unset;
  cursor: pointer;

  transition: 0.2s ease-in;
  &.selected-status {
    border: 4px solid #F0A926;
  }
  &.no-selected-status {
    border: 4px solid transparent;
  }
}

.badge-not-paid, .badge-late, .badge-past-due { background-color: #C50532; }
.badge-paid, .badge-accepted { background-color: #FDF1E2; color: #FF8000 !important; }
.badge-picked-up { background-color: #EEEEEE; color: #888888 !important; }
.badge-in-progress, .badge-authorized-for-capture, .badge-queued-for-capture { background-color: #6BCA71; color: #FFFFFF !important; }
.badge-not-picked-up { background-color: #D5EFD4; color: #199A49 !important; }
.badge-ready, .badge-finished { background-color: #D5EFD4; color: #199A49 !important; }
.badge-received { background-color: #198D48; color: #FFFFFF !important; }
.badge-invoiced { background-color: #F9B6AC; color: #FFFFFF !important; }
.badge-completed { background-color: #EEEEEE; color: #888888 !important; }

@import "assets/css/bootstrap-grid";


.gutter-container{
  padding: 0 5rem;
}


.form-group {
  label {
    font-size: calc(1rem - 4px);
    font-weight: 400;
    margin-bottom: calc(1rem - 8px);
  }
 
  select.form-field,
  input.form-field,
  textarea.form-field {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background: #F4F4F4;
    border-radius: calc(.4rem);
    width: 100%;
    padding: .6rem 1.2rem ;
    border: none;
    outline: none;
    transition: .3s all ease;
    font-size: calc(1rem - 2px);
    position: relative;
    font-family: 'Source Sans Pro', sans-serif;
    &.dollar-sign {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='7' y='13' fill='gray' font-size='12' font-family='arial'>$</text></svg>");
      background-repeat: no-repeat;
      background-position: left;
    }
  }
  textarea {
    min-height: 152px;
    max-height: 152px;
    resize: none;
  }
  select {
    appearance: none;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #F4F4F4 !important;
    background-position-x: 96% !important;
    cursor: pointer;
    padding: .68rem 1.2rem !important;
  }
}

.square-color {
  width: 10px;
  height: 10px;
  border-radius: 3px;
  border: 1px solid transparent;
  &.black {
    background: #000;
  }
  &.gray {
    background: #808080;
  }
  &.white {
    background: #FFFFFF;
    border-color: #000;
  }
  &.beige {
    background: #DEB887;
  }
  &.red {
    background: #FF0000;
  }
  &.orange {
    background: #FFA500;
  }
  &.yellow {
    background: #FFFF00;
  }
  &.pink {
    background: #FFC0CB;
  }
  &.green {
    background: #008000;
  }
  &.blue {
    background: #0000FF;
  }
  &.purple {
    background: #800080;
  }
  &.brown {
    background: #A52A2A;
  }
  &.mixed {
    background: #778899;
  }
}


// Custom gutter
.g-25{
  --bs-gutter-x: 0.6rem;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}



.w-100 {
  width: 100% !important;
}
.w-90 {
  width: 90% !important;
  max-width: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.fadein {
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn
{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes spin
{
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1s forwards infinite linear;
}



.red-border {
  transition: .3s all ease;
  border: 2px solid #C50848;
}

.transparent-border {
  transition: .3s all ease;
  border: 2px solid transparent;
}



.selected-bar {
  border-bottom: 2px solid #C50532;
}
.rc-menu__submenu > .rc-menu__item::before
{
  font-family: 'Font Awesome 5 Free';
  content: "\f362";
  position: absolute;
  left: .7rem;
  font-weight: bold;
}


.big-status {
  font-size: 2rem;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
}

.big-text {
  font-size: 1.5rem !important;
}

.medium-text {
  font-size: 1.2rem !important;
}

.mw-720 {
  max-width: 720px !important;
}

.cursor-default {
  cursor: default !important;
}

.error-banner {
  color: #842029;
  background: #f8d7da;
  border: 1px solid #f5c2c7;
  padding: .85rem .75rem;
  border-radius: .25rem;
  font-weight: 600;
}

.swal2-container {
  z-index: 9999999 !important;
}

.flex-1-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  &.row-container {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .flex-col {
    overflow-y: auto;
  }
}


.rc-menu--open.submenu,
.rc-menu--closing.submenu {
  width: 100px !important;
  font-size: 1rem !important;
}

.my-menuitem {
  font-size: .75rem !important;
  padding: .25rem .75rem .25rem .5rem !important;
  img {
    width: 1rem !important;
    height: 1rem !important;
    transform: scale(1.3);
    margin-right: .5rem !important;
  }
}



.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}


.modal-medium {
  max-width: 960px !important;
}

.modal-496 {
  max-width: 496px !important;
}

.modal-1016 {
  max-width: 1016px !important;
}

.modal-large {
  max-width: 1200px !important;
}

.ReactModal__Content--after-open {
  // background-color: red !important;'
  position: absolute !important;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 8px;
  background: rgb(255, 255, 255);
  overflow: auto; border-radius: 4px;
  outline: none;
  padding: 1rem;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  // border-radius: 50rem !important;
  max-width: 720px;
  height: 90vh;
  max-height: 90vh;
  width: 90%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-radius: 8px;
}
.ReactModal__Content--after-open {
  // background-color: red !important;'
  position: absolute !important;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 8px;
  background: rgb(255, 255, 255);
  overflow: auto; border-radius: 4px;
  outline: none;
  padding: 1rem;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  // border-radius: 50rem !important;
  max-width: 720px;
  height: 90vh;
  max-height: 90vh;
  width: 90%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-radius: 8px;
}

.modal-fit {
  width: fit-content !important;
  height: fit-content !important;
}

@media screen and (max-width: 767px) {
  .ReactModal__Content--after-open {
    height: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    transform: none !important;


    &.modal-fit {
      width: fit-content !important;
      height: fit-content !important;
      transform: translate(-50%, -50%) !important;
      left: 50% !important;
      top: 50% !important;
    }
    &.modal-90 {
      width: 90% !important;
    }
    &.modal-h {
      height: calc(100vh - 3rem) !important;
    }


  }
}
.modal-height {
  min-height: fit-content !important;
  height: fit-content !important;
  max-height: fit-content !important;
}
  .transition-3-all-ease {
    transition: .3s all ease;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.ReactModal__Overlay--after-open {
  z-index: 10;
  background: rgba(#000, .7) !important;
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.rdrStartEdge,
.rdrEndEdge {
  background-color: #F28705 !important;
  color: #FFF !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background-color: #F28705 !important;
}

.rdrInRange {
  background-color: #F28705 !important;
}

@media screen and (max-width: 1023px) {
  .hide-1023 {
    display: none;
  }
  .mw-500-1023 {
    max-width: 500px;
    width: 100%;
  }
  .mx-auto-1023 {
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) {
  .hide-1024 {
    display: none;
  }
}

.fade-up {
  animation: fadeUp .3s ease-in;
}

@keyframes fadeUp {
  from {
    transform: translate(-50%, 50%);
  }
  to {
    transform: translateY(-50%, -50%);
  }
}

// @media screen and (max-width: 767px) {
//   .col-sm-12 {
//     width: 100%;
//   }
// }
.order-header {
  padding: 1rem;
  border-bottom: 2px solid #CCCCCC;
}

.orders {
  width: 100%;
  display: table;
  min-height: 100%;
}

.orders-column {
  display: table-cell;
  border-right: 2px solid #CCCCCC;
}

.orders-column:last-child {
  border-right: 0 !important;
}

.orders-cell {
  cursor: pointer;
  padding: 1rem;
}

.orders-cell:hover {
  background-color: #F4F4F4;
}

.active {
  background-color: #F1EBE6;
}

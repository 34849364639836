.agenda-component {
    table {
        width: 100%;
    }

    th {
        font-weight: 400;
        color: #AAAAAA;
        position: sticky;
        top: 2rem;
        background: #FFF;
        z-index: 1;
    }

    th,
    td {
        font-size: calc(1rem - 4px);
        text-align: center;
        // padding: .5rem 0;
        padding: 8px 12px 10px 12px;
        max-width: 92px;
        min-width: 92px;
    }

    td {
        cursor: pointer;
        user-select: none;
        background: #F4F4F4;
        border: 1px solid #CCC;
        border-right: none;
        text-align: left;
        transition: .3s all ease;
        min-height: 120px;
        max-height: 120px;
        &:first-child {
            border-left: none;
        }
        &.not-current {
            background: #FFF;
            opacity: .3;
        }
        &.selected { 
            background: #FFEEDA;
        }
    }
    

    span.current-day {
        background: #000;
        color: #FFF;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
    }


}
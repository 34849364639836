.load-spinner-component {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    z-index: 9999999;
    div.loading-spinner-container {
        border-radius: 1rem;
        max-width: 300px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 2rem;
        img {
            width: 100%;
            margin-bottom: 1rem;
        }
        h6,
        span {
            font-size: 1.2rem;
            text-align: center;
            font-weight: 400;
            position: relative;
            div {
                position: absolute;
                right: 0;
                top: 0;
                width: 99%;
                height: 100%;
                background: #FFF;
                animation: loading-loop 1s linear infinite;
            }
        }

    }
    @keyframes loading-loop {
        0% {
            width: 100%;
        }
        25% {
            width: 66%;
        }
        50% {
            width: 33%;
        }
        75% {
            width: 0%;
        }
        100% {
            width: 0;
        }
    }

    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 160px;
        height: 160px;
      }
      .lds-ripple div {
        position: absolute;
        border: 4px solid #fff;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
      .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
      }
      @keyframes lds-ripple {
        0% {
          top: 72px;
          left: 72px;
          width: 0;
          height: 0;
          opacity: 1;
        }
        100% {
          top: 0px;
          left: 0px;
          width: 144px;
          height: 144px;
          opacity: 0;
        }
      }
      
}
$itemBgColor: #F4F4F4;
$defaultFont: 'Source Sans Pro', sans-serif;
$defaultFontWeight: 'Semibold';
$baseFontSize: 16px;
$defaultBorderRadius: 8px;
$selectedItemBorderColor: #F28705;
$selectedItemBgColor: #FFEEDA;
$activeItemBgColor: #EAE3DD;

.color-active{
  background-color: $activeItemBgColor !important;
}

.color-picker-cnt {
  font-size: $baseFontSize;
  background: #fff;
  border-radius: $defaultBorderRadius;

  p, span, img {
    user-select: none;
  }

  > .column {
    margin-bottom: 0.4rem;
  }

  .selected-layer {
    display: none;

    .column {
      display: flex;
    }
  }

  .header {
    margin: 1.25rem 0;
  }

  .item-type {
    border: 1px solid transparent;
    background-color: $itemBgColor;
    border-radius: $defaultBorderRadius;
    padding: 1rem 0;
    transition: all .1s ease 0s;
    cursor: pointer;

    .item-name {
      position: relative;
      top: 0;
      transition: all .4s ease 0s;
    }

    &.active {
      background-color: $selectedItemBgColor;
      border-color: $selectedItemBorderColor;

      .item-name {
        top: -60px;
      }

      .item-img {
        opacity: .3;
      }

      .selected-layer {
        display: inline;
        div[class="col-*"]{
          display: flex;
          justify-content: center;
        }
      }
    }

    .item-btn {
      border-radius: 50%;
      background-color: #fff;
      width: 32px;
      height: 32px;

      border:none;

      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }
}





.delete-item{
  font-size: 12px;
  font-weight: bold;
  color: red;
}


.item-text{
  font-size: 16px;
  font-weight: bold;
}

.btn-add-note{
  background-color: #F28705;
  font-size: 16px;
  font-weight: bold;
  color:white;
  padding: 1rem 4rem ;
  border: none;
  border-radius: 30px;
}


.top-border{
  border-top: 3px solid #F4F4F4;
}

.disabled {
  opacity: 0.6;
}

h3 {
  margin: 0;
  padding: 0;
}
html, body, .app {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100vh;
}
.modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999999999;
  background-color: rgba(0,0,0, 0.5);
}
.modal-component {
  margin: auto;
  z-index: 99999999;
  position: absolute;

  background: white;
  border-radius: 4px;
}

.modal-body {
  padding: 1rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 5px;
    margin-bottom: 15px;

    img {
      cursor: pointer;
    }
  }

  hr {
    opacity: 0.7;
    margin-bottom: 15px !important;
  }

  .body p {
    font-size: 0.8rem;
    font-weight: normal;
    text-align: center;
  }
}

.modal-confirm-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: 1.5rem;

  button {
    width: 48%;
  }
}

@media screen and (max-width: 576px) {
  .modal-component {
    top: 5%;
    left: 5%;
    right: 5%;
  }
}

@media screen and (min-width: 576px) {
  .modal-component {
    top: 15%;
    left: 25%;
    right: 25%;
  }
}

@media screen and (min-width: 768px) {
  .modal-component {
    top: 25%;
    left: 25%;
    right: 25%;
  }
}

@media screen and (min-width: 992px) {
  .modal-component {
    top: 25%;
    left: 35%;
    right: 35%;
  }
}



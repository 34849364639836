.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 2.6rem;
}

.App-header ul {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 1.8rem 0 0 0;
}

.App-header ul li {
  margin-right: calc(2rem + 8px);
}

.App-header a {
  color: rgb(139, 139, 139);
  text-decoration: none;
  transition: .3s all ease;
  font: normal normal 600 calc(1rem - 4px) Source Sans Pro;
  letter-spacing: 0;
  cursor: pointer;
  background: transparent;
}

.App-header a:hover {
  color: rgb(104, 103, 103);
}

.App-header a.active {
  color: #F28705;
}

button.logout {
  padding: 10px;
  border-radius: 10px;
  border: none;
  background: #F28705;
  color: #FFF;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

button.logout:disabled {
  opacity: .3;
}


.App-link {
  color: #61dafb;
}


// MEDIA QUERY FOR XD DESIGN SIZE
html, body {
}

.main-wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 96% !important;
  width: 90% !important;
  .main-content{
    overflow: scroll;
    height: calc(100vh - 113px) !important;
  }
}

@media screen and (min-width: 1919px) {
  .main-wrapper {
    width: calc(100% - 480px);
    margin: auto;
    .main-content{
      padding: 0 107px;
      overflow: hidden;
    }
  }
}

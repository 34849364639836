.message-box-container {
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    &.shown {
        pointer-events: all;
        transition: .4s all ease;
        background: rgba($color: #000000, $alpha: .7);
    }
    div.box {
        position: absolute;
        min-width: 100px;
        max-width: 400px;
        width: 90%;
        padding: 1rem;
        background: #FFF;
        left: 50%;
        top: -100%;
        transform: translateX(-50%);
        border-radius: 1rem;
        pointer-events: none;
        opacity: 0;
        overflow: auto;
        &.shown {
            opacity: 1;
            transition: .25s top linear;
            pointer-events: all;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        h4 {
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: .3rem;
            position: sticky;
            left: 0;
        }
        p {
            font-size: 1rem;
            font-weight: 400;
            margin-bottom: .84rem;
        }
    }
}
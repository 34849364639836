$itemBgColor: #F4F4F4;
$defaultFont: 'Source Sans Pro', sans-serif;
$defaultFontWeight: 'Semibold';
$baseFontSize: 16px;
$defaultBorderRadius: 8px;
$selectedItemBorderColor: #F28705;
$selectedItemBgColor: #FFEEDA;

.item-picker-cnt {
  font-size: $baseFontSize;
  background: #fff;
  border-radius: $defaultBorderRadius;

  p, span, img {
    user-select: none;
  }

  > .column {
    margin-bottom: 0.4rem;
  }

  .selected-layer {
    visibility: hidden;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 10px;
    padding: 0 5px;

    .column {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .header {
    margin: 0 0 1.25rem 0;
  }

  .item-type {
    border: 1px solid transparent;
    height: 152px;
    background-color: $itemBgColor;
    border-radius: $defaultBorderRadius;
    padding: 1.4rem .5rem;
    position: relative;
    cursor: pointer;
    //min-width: 152px; // following XD design

    .item-name {
      position: relative;
      top: 0;
      transition: all .4s ease 0s;
    }

    .item-img {
      max-width: 40%;
    }

    &.active {
      background-color: $selectedItemBgColor;
      border-color: $selectedItemBorderColor;

      .item-name {
        top: -60px;
      }

      .item-img {
        opacity: .3;
      }

      .selected-layer {
        visibility: visible;
      }
    }

    .item-btn {
      border-radius: 50%;
      background-color: #fff;
      margin: auto;
      width: 32px;
      height: 32px;

      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }
}
